import React,{useState} from "react"
import "./contactusformusformglobal.scss"
import Swal from 'sweetalert2'
import axios from "axios";
const ContactFormUsFormGlobal = ({open, setOpen, isActive, setIsActive}) => {

    const [formData,setFormData] = useState({
        name:"",
        email:"",
        package:"",
        phoneNumber:"",
        message:"",
    });

    const handleToggle = () => {
        setOpen(!open);
        setIsActive(!isActive); // Toggle the active class
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataNew = {
            type:"contactUs",
            fullName:formData?.name,
            email:formData?.email,
            mobileNumber:formData?.phoneNumber,
            package:formData?.package,
            message:formData?.message,

            // totalTravellers:"2"
        };
        try {
            const response = await axios.post("https://api.chicagohajj.com/website/inquiry", formDataNew);
            console.log(response.data);
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Your ContactUs form submit successfully",
                showConfirmButton: false,
                timer: 1500
            });
            setFormData({})
            // handle successful response
        } catch (error) {
            console.error(error);
            // handle error
        }


    };
        return(

        <>
            {open ? (  <div className="cfg-maindiv">

                <div className="cfg-maindiv-headings">
                    <div className="cfg-maindiv-headings-crossbtn">
                        <h3>Need Help? Contact Us Now</h3>
                        <span onClick={handleToggle}>x</span>
                    </div>


                    <p>Fill the form below and our representative will be in touch with you.</p>
                </div>
                <form onSubmit={handleSubmit} className="cfg-maindiv-form">
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        placeholder="Name"
                        onChange={handleChange}
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <select
                        name="package"
                        value={formData.package}
                        onChange={handleChange}
                    >
                        <option value="">Select a package</option>

                        <option value="standard">Standard</option>
                        <option value="economy">Economy</option>
                        <option value="premium">Premium</option>
                        <option value="luxury">Luxury</option>

                    </select>
                    <input
                        type="text"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        placeholder="Phone Number"
                        onChange={handleChange}
                    />
                    <textarea
                        name="message"
                        value={formData.message}
                        placeholder="Your Message"
                        onChange={handleChange}
                    />
                    <div className='cfg-maindiv-form-btndiv'>
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>) : null}
        </>
        )



}

export default ContactFormUsFormGlobal